import useProductMethods from './useProductMethods';
import generateContext from '../helpers/context';
import { computed } from '@nuxtjs/composition-api';
import { sharedRef, Logger } from '@vue-storefront/core';

import type { Ref } from '@nuxtjs/composition-api';
import type { UseProductErrors, UseProduct } from '@vue-storefront/core';
import type { ProductVariant } from '@vsf-enterprise/commercetools-types';
import type { ProductsSearchParams } from '../types';

/**
 * Composable for loading products
 */
function useProduct(id: string): UseProduct<ProductVariant[], ProductsSearchParams> {
  const context = generateContext(useProductMethods);
  const products: Ref<ProductVariant[]> = sharedRef([], `useProduct-products-${id}`);
  const loading = sharedRef(false, `useProduct-loading-${id}`);
  const error: Ref<UseProductErrors> = sharedRef({
    search: null
  }, `useProduct-error-${id}`);

  /**
   * Searches for product with given params
   */
  async function search(searchParams) {
    Logger.debug(`useProduct/${id}/search`, searchParams);

    try {
      loading.value = true;
      products.value = await useProductMethods.productsSearch(context, searchParams);
      error.value.search = null;
    } catch (err) {
      error.value.search = err;
      Logger.error(`useProduct/${id}/search`, err);
    } finally {
      loading.value = false;
    }
  }

  return {
    search,
    products: computed(() => products.value),
    loading: computed(() => loading.value),
    error: computed(() => error.value)
  };
}

export {
  useProduct
};

import { computed } from '@nuxtjs/composition-api';
import { sharedRef, Logger } from '@vue-storefront/core';
import type { Ref } from '@nuxtjs/composition-api';
import type { Context } from '@vue-storefront/core';
import type { Address } from '@vsf-enterprise/commercetools-types';
import { UseShipping, UseShippingErrors } from '../types';
import generateContext from '../helpers/context';
import useShippingMethods from './useShippingMethods';

/**
 * Composable for managing shipping addresses for the current order
 */
function useShipping(): UseShipping<Address, any> {
  const context: Context = generateContext(useShippingMethods);
  const loading: Ref<boolean> = sharedRef(false, 'useShipping-loading');
  const shipping: Ref<Address> = sharedRef(null, 'useShipping-shipping');
  const error: Ref<UseShippingErrors> = sharedRef({
    load: null,
    save: null,
    clear: null,
    saveCollectable: null
  }, 'useShipping-error');

  /**
   * Loads saved shipping info
   */
  async function load({ customQuery = null } = {}) {
    Logger.debug('useShipping.load');

    try {
      loading.value = true;
      const shippingInfo = await useShippingMethods.load(context, { customQuery });
      error.value.load = null;
      shipping.value = shippingInfo;
    } catch (err) {
      error.value.load = err;
      Logger.error('useShipping/load', err);
    } finally {
      loading.value = false;
    }
  }

  /**
   * Saves shipping info
   */
  async function save(saveParams) {
    Logger.debug('useShipping.save');

    try {
      loading.value = true;
      const shippingInfo = await useShippingMethods.save(context, saveParams);
      error.value.save = null;
      shipping.value = shippingInfo;
    } catch (err) {
      error.value.save = err;
      Logger.error('useShipping/save', err);
    } finally {
      loading.value = false;
    }
  }

  /**
   * Clears shipping info
   */
  async function clear(clearParams = {}) {
    Logger.debug('useShipping.clear', clearParams);

    try {
      loading.value = true;
      const shippingInfo = await useShippingMethods.clear(context, clearParams);
      error.value.clear = null;
      shipping.value = shippingInfo;
    } catch (err) {
      error.value.clear = err;
      Logger.error('useShipping/clear', err);
    } finally {
      loading.value = false;
    }
  }

  /**
   * Saves Click&Collect shipping info
   */
  async function saveCollectable(saveParams) {
    Logger.debug('useShipping.save');

    try {
      loading.value = true;
      const shippingInfo = await useShippingMethods.saveCollectable(context, saveParams);
      error.value.saveCollectable = null;
      shipping.value = shippingInfo;
    } catch (err) {
      error.value.saveCollectable = err;
      Logger.error('useShipping/save', err);
    } finally {
      loading.value = false;
    }
  }

  return {
    shipping: computed(() => shipping.value),
    loading: computed(() => loading.value),
    error: computed(() => error.value),
    load,
    save,
    clear,
    saveCollectable
  };
}

export {
  useShipping
};

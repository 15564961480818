import generateContext from '../helpers/context';
import useCategoryMethods from './useCategoryMethods';
import { computed } from '@nuxtjs/composition-api';
import { Logger, sharedRef } from '@vue-storefront/core';

import type { Ref } from '@nuxtjs/composition-api';
import type { Context, UseCategory, UseCategoryErrors } from '@vue-storefront/core';
import type { Category } from '@vsf-enterprise/commercetools-types';

/**
 * Composable for loading categories
 */
function useCategory(id: string): UseCategory<Category, any> {
  const context: Context = generateContext(useCategoryMethods);
  const categories: Ref<Category[]> = sharedRef([], `useCategory-categories-${id}`);
  const loading: Ref<boolean> = sharedRef(false, `useCategory-loading-${id}`);
  const error: Ref<UseCategoryErrors> = sharedRef({
    search: null
  }, `useCategory-error-${id}`);

  /**
   * Searches for category with given params
   */
  async function search(searchParams) {
    Logger.debug(`useCategory/${id}/search`, searchParams);

    try {
      loading.value = true;
      categories.value = await useCategoryMethods.categorySearch(context, searchParams);
      error.value.search = null;
    } catch (err) {
      error.value.search = err;
      Logger.error(`useCategory/${id}/search`, err);
    } finally {
      loading.value = false;
    }
  }

  return {
    search,
    loading: computed(() => loading.value),
    categories: computed(() => categories.value),
    error: computed(() => error.value)
  };
}

export {
  useCategory
};

import { enhanceProduct as originalEnhanceProduct, EnhanceProduct, mapPaginationParams } from '../helpers/internals';
import { ErrorResponse, ProductsSearchParams } from '../types';
import { catchApiErrors } from '../helpers/internals/handleApiErrors';
import type { Context, CustomQuery } from '@vue-storefront/core';
import type { ProductVariant } from '@vsf-enterprise/commercetools-types';

type UseProductMethods<PRODUCTS, PRODUCT_SEARCH_PARAMS> = {
  productsSearch: (context: Context, params: PRODUCT_SEARCH_PARAMS & { customQuery?: CustomQuery, enhanceProduct?: EnhanceProduct }) => Promise<PRODUCTS>;
};

const useProductMethods: UseProductMethods<ProductVariant[], ProductsSearchParams> = {
  productsSearch: async (context: Context, { customQuery, enhanceProduct, ...searchParams }): Promise<ProductVariant[]> => {
    const apiSearchParams = {
      ...searchParams,
      ...mapPaginationParams(searchParams)
    };

    // @ts-ignore
    const response = await context.$ct.api.getProduct(apiSearchParams, customQuery);
    catchApiErrors(response as unknown as ErrorResponse);
    if (customQuery) return response as any;
    // @ts-ignore
    const enhancedProductResponse = (enhanceProduct || originalEnhanceProduct)(response, context);
    return (enhancedProductResponse.data as any)._variants;
  }
};

export default useProductMethods;

import loadCurrentWishlist from './currentWishlist';
import { ErrorResponse } from '../types';
import { catchApiErrors } from '../helpers/internals/handleApiErrors';
import type { Context } from '@vue-storefront/core';
import type { LineItemIdentifier, ProductIdentifier } from '@vsf-enterprise/commercetools-api';
import type { LineItem, ProductVariant, ShoppingList, ShoppingListLineItem } from '@vsf-enterprise/commercetools-types';
import type { CustomQuery } from '@vsf-enterprise/commercetools-api';

type UseWishlistMethods<WISHLIST, WISHLIST_ITEM, PRODUCT> = {
  load: (context: Context, params: { customQuery?: CustomQuery }) => Promise<WISHLIST>;
  addItem: (
    context: Context,
    params: {
      currentWishlist: WISHLIST;
      product: PRODUCT;
      customQuery?: CustomQuery;
    }
  ) => Promise<WISHLIST>;
  removeItem: (
    context: Context,
    params: {
      currentWishlist: WISHLIST;
      product: WISHLIST_ITEM;
      customQuery?: CustomQuery;
    }
  ) => Promise<WISHLIST>;
  clear: (context: Context, params: { currentWishlist: WISHLIST }) => Promise<WISHLIST>;
  isInWishlist: (context: Context, params: { currentWishlist: WISHLIST; product: PRODUCT }) => boolean;
};

const getCurrentWishlist = async (context, currentWishlist) => {
  if (!currentWishlist) {
    return await loadCurrentWishlist(context);
  }

  return currentWishlist;
};

const getWishlistItemByProduct = ({ currentWishlist, product }) => {
  return (
    currentWishlist &&
    currentWishlist.lineItems &&
    currentWishlist.lineItems.length &&
    currentWishlist.lineItems.find((item: ShoppingListLineItem) => item.variant.sku === product.sku)
  );
};

const useWishlistMethods: UseWishlistMethods<Partial<ShoppingList>, LineItem, ProductVariant> = {
  load: async (context, { customQuery }) => {
    const isGuest = await context.$ct.api.isGuest();
    if (isGuest) {
      return null;
    }
    const response = await context.$ct.api.getMyShoppingList(customQuery);
    catchApiErrors(response as unknown as ErrorResponse);

    return response.data?.me?.shoppingLists?.results?.[0] || null;
  },

  addItem: async (context, { currentWishlist, product, customQuery }) => {
    const { id, version } = await getCurrentWishlist(context, currentWishlist);
    const productIdentifier = product as ProductIdentifier;
    const response = await context.$ct.api.addToMyShoppingList(
      {
        product: productIdentifier,
        id,
        version
      },
      customQuery
    );
    catchApiErrors(response as unknown as ErrorResponse);

    return response.data.wishlist;
  },

  removeItem: async (context, { currentWishlist, product, customQuery }) => {
    const { id, version } = await getCurrentWishlist(context, currentWishlist);
    const lineItemIdentifier = product as LineItemIdentifier;
    const response = await context.$ct.api.removeFromMyShoppingList(
      {
        products: [lineItemIdentifier],
        id,
        version
      },
      customQuery
    );
    catchApiErrors(response as unknown as ErrorResponse);

    return response.data.wishlist;
  },

  clear: async (context, { currentWishlist }) => {
    const { lineItems, id, version } = await getCurrentWishlist(context, currentWishlist);
    const response = await context.$ct.api.removeFromMyShoppingList({
      products: lineItems,
      id,
      version
    });
    catchApiErrors(response as unknown as ErrorResponse);

    return response.data.wishlist;
  },

  isInWishlist: (context, { currentWishlist, product }) => {
    return Boolean(currentWishlist && getWishlistItemByProduct({ currentWishlist, product }));
  }
};

export default useWishlistMethods;

import generateContext from '../helpers/context';
import useUserOrderMethods from './useUserOrderMethods';
import { computed } from '@nuxtjs/composition-api';
import { sharedRef, Logger } from '@vue-storefront/core';

import type { Ref } from '@nuxtjs/composition-api';
import type { Context, UseUserOrderErrors, UseUserOrder } from '@vue-storefront/core';
import type { OrderQueryResult } from '@vsf-enterprise/commercetools-types';
import type { OrderSearchParams } from '../types';

/**
 * Composable for loading user order history
 */
function useUserOrder(): UseUserOrder<OrderQueryResult, OrderSearchParams> {
  const context: Context = generateContext(useUserOrderMethods);
  const orders: Ref<OrderQueryResult> = sharedRef({
    results: [],
    total: 0
  }, 'useUserOrder-orders');
  const loading: Ref<boolean> = sharedRef(false, 'useUserOrder-loading');
  const error: Ref<UseUserOrderErrors> = sharedRef({}, 'useUserOrder-error');

  /**
   * Searches orders with given params
   */
  async function search(searchParams) {
    Logger.debug('useUserOrder.search', searchParams);

    try {
      loading.value = true;
      orders.value = await useUserOrderMethods.searchOrders(context, searchParams);
      error.value.search = null;
    } catch (err) {
      error.value.search = err;
      Logger.error('useUserOrder/search', err);
    } finally {
      loading.value = false;
    }
  }

  return {
    orders: computed(() => orders.value),
    search,
    loading: computed(() => loading.value),
    error: computed(() => error.value)
  };
}

export {
  useUserOrder
};

import type { UserGetters } from '@vue-storefront/core';
import type { Customer } from '@vsf-enterprise/commercetools-types';

function getFirstName(user: Customer): string {
  return user?.firstName || '';
}

function getLastName(user: Customer): string {
  return user?.lastName || '';
}

function getFullName(user: Customer): string {
  return user ? `${user.firstName} ${user.lastName}` : '';
}

function getEmailAddress(user: Customer): string {
  return user?.email || '';
}

function getDateOfBirth(user: Customer): string {
  return user?.dateOfBirth || '';
}

/**
 * Getter for the `useUser` composable
 */
export const userGetters: UserGetters<Customer> = {
  getFirstName,
  getLastName,
  getFullName,
  getEmailAddress,
  getDateOfBirth
};

import { UserOrderGetters, AgnosticOrderStatus } from '@vue-storefront/core';
import { OrderState } from '@vsf-enterprise/commercetools-types';
import type { Order, LineItem, OrderQueryResult } from '@vsf-enterprise/commercetools-types';

const orderStatusMap = {
  [OrderState.Open]: AgnosticOrderStatus.Open,
  [OrderState.Confirmed]: AgnosticOrderStatus.Confirmed,
  [OrderState.Complete]: AgnosticOrderStatus.Complete,
  [OrderState.Cancelled]: AgnosticOrderStatus.Cancelled
};

function getDate(order: Order): string {
  return order?.createdAt || '';
}

function getId(order: Order): string {
  return order?.id || '';
}

function getStatus(order: Order): AgnosticOrderStatus | '' {
  return order?.orderState ? orderStatusMap[order.orderState] : '';
}

function getPrice(order: Order): number {
  return order ? order.totalPrice.centAmount / 100 : 0;
}

function getItems(order: Order): LineItem[] {
  return order?.lineItems || [];
}

function getItemSku(item: LineItem): string {
  return item?.productId || '';
}

function getItemName(item: LineItem): string {
  return item?.name || '';
}

function getItemQty(item: LineItem): number {
  return item?.quantity || 0;
}

function getItemPrice(item: LineItem): number {
  return item ? item.price.value.centAmount / 100 : 0;
}

function getFormattedPrice(price: number) {
  return price as any as string;
}

function getOrdersTotal(orders: OrderQueryResult) {
  return orders.total;
}

/**
 * Getter for the `useUserOrder` composable
 */
export const orderGetters: UserOrderGetters<Order, LineItem> = {
  getDate,
  getId,
  getStatus,
  getPrice,
  getItems,
  getItemSku,
  getItemName,
  getItemQty,
  getItemPrice,
  getFormattedPrice,
  getOrdersTotal
};

import { CustomQuery } from '@vue-storefront/core';
import { CustomerSignMeInDraft, CustomerSignMeUpDraft } from '@vsf-enterprise/commercetools-types';
import { catchApiErrors } from '../helpers/internals/handleApiErrors';
import { ErrorResponse } from '../types';

type UserData = CustomerSignMeUpDraft | CustomerSignMeInDraft;

const validateUserResponse = (response) => {
  if (response?.data?.user) {
    return response.data.user;
  }
  throw response;
};

export const authenticate = async (userData: UserData, fn, customQuery?: CustomQuery) => {
  const userResponse = await fn(userData, customQuery);
  catchApiErrors(userResponse as ErrorResponse);
  return validateUserResponse(userResponse);
};

import { ErrorHandling } from '../../types';

export const catchApiErrors: ErrorHandling = ({ graphQLErrors, networkError, simple = false }, customError) => {
  if (customError && !graphQLErrors) {
    throw new Error(customError?.message || 'Something went wrong. Please try again.');
  }
  if (graphQLErrors && graphQLErrors.length > 0) {
    throw simple ? new Error(graphQLErrors[0].message) : graphQLErrors[0];
  }
  if (networkError) {
    throw networkError;
  }
};

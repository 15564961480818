import { authenticate } from './authenticate';
import { useCart } from '../useCart';
import { useWishlist } from '../useWishlist';
import { mergeWishlists } from './mergeWishlists';
import { catchApiErrors } from '../helpers/internals/handleApiErrors';
import { ErrorResponse } from '../types';

import type { Context } from '@vue-storefront/core';
import type { Customer } from '@vsf-enterprise/commercetools-types';
import type { CustomQuery } from '@vsf-enterprise/commercetools-api';
type UseUserMethods<USER, UPDATE_USER_PARAMS, REGISTER_USER_PARAMS> = {
  provide: (context: Context) => any;
  load: (context: Context, params?: { customQuery: CustomQuery }) => Promise<USER>;
  logOut: (context: Context, params?: {currentUser: USER}) => Promise<void>;
  updateUser: (context: Context, params: {currentUser: USER; updatedUserData: UPDATE_USER_PARAMS; customQuery?: CustomQuery}) => Promise<USER>;
  register: (context: Context, params: REGISTER_USER_PARAMS & {customQuery?: CustomQuery}) => Promise<USER>;
  logIn: (context: Context, params: { username: string; password: string; customQuery?: CustomQuery }) => Promise<USER>;
  changePassword: (context: Context, params: {currentUser: USER; currentPassword: string; newPassword: string; customQuery?: CustomQuery}) => Promise<USER>;
}

const load = async (context: Context, {customQuery}) => {
  const profile = await context.$ct.api.getMe({ customer: true }, customQuery);

  context.cart.setCart(profile.data.me.activeCart);

  return profile.data.me.customer;
};

const getCurrentUser = async (context: Context, currentUser, customQuery) => {
  if (!currentUser) {
    return load(context, {customQuery});
  }

  return currentUser;
};

/**
 * @remarks References:
 * {@link @vsf-enterprise/commercetools-api#Customer}
 */
const useUserMethods: UseUserMethods<Partial<Customer>, any, any> = {
  provide() {
    return {
      cart: useCart(),
      wishlist: useWishlist()
    };
  },
  load,
  logOut: async (context: Context) => {
    await context.$ct.api.customerSignOut();

    context.cart.setCart(null);
    context.wishlist.setWishlist(null);
  },
  updateUser: async (context: Context, { currentUser, updatedUserData, customQuery }) => {
    const loadedUser = await getCurrentUser(context, currentUser, customQuery);
    const { user } = await context.$ct.api.customerUpdateMe(loadedUser, updatedUserData, customQuery);

    return user;
  },
  register: async (context: Context, { email, password, firstName, lastName, customQuery }) => {
    const { customer, cart } = await authenticate({email, password, firstName, lastName}, context.$ct.api.customerSignMeUp, customQuery);

    context.cart.setCart(cart);

    return customer;
  },
  logIn: async (context: Context, { username, password, customQuery }) => {
    const customerLogin = { email: username, password };
    const { customer, cart } = await authenticate(customerLogin, context.$ct.api.customerSignMeIn, customQuery);

    context.cart.setCart(cart);
    await mergeWishlists(context);

    return customer;
  },
  changePassword: async function changePassword(context: Context, { currentUser, currentPassword, newPassword, customQuery }) {
    const loadedUser = await getCurrentUser(context, currentUser, customQuery);
    const userResponse = await context.$ct.api.customerChangeMyPassword(loadedUser.version, currentPassword, newPassword);
    catchApiErrors(userResponse as unknown as ErrorResponse);
    // we do need to re-authenticate user to acquire new token - otherwise all subsequent requests will fail as unauthorized
    await useUserMethods.logOut(context);

    return await useUserMethods.logIn(context, { username: userResponse.data.user.email, password: newPassword });
  }
};

export default useUserMethods;

import type { Context } from '@vue-storefront/core';
import { StoresData } from '../types';
import type { StoresWhereSearch } from "@vsf-enterprise/commercetools-types";

type UseStoreMethods<STORES> = {
  load: (context: Context, params?: StoresWhereSearch) => Promise<STORES>;
}

const useStoreMethods: UseStoreMethods<StoresData> = {
  load: async (context: Context, params?: StoresWhereSearch) => {
    const { api, config } = context.$ct;
    const stores = await api.getStores(params);

    return <StoresData>{
      stores,
      _selectedStore: config.store
    };
  }
};

export default useStoreMethods;

import type { Context } from '@vue-storefront/core';
import type {
  Category,
  FacetResultValue,
  TermsFacetResult
} from '@vsf-enterprise/commercetools-types';

// List of available data types: https://docs.commercetools.com/api/projects/types
const quotedDataTypes = [
  'string',
  'boolean'
];

const buildBreadcrumbsList = (rootCat, bc) => {
  const newBc = [...bc, { text: rootCat.name, link: rootCat.slug }];
  return rootCat.parent ? buildBreadcrumbsList(rootCat.parent, newBc) : newBc;
};

const filterByCategory = async (context: Context, { categorySlug, rootCatSlug }) => {
  if (!categorySlug && !rootCatSlug) {
    return [];
  }

  const categoryResponse = await context.$ct.api.getFacetCategories({ slugs: [categorySlug, rootCatSlug] });
  const results = categoryResponse.data?.categories?.results || [];

  if (!results || results.length === 0) {
    return [];
  }

  if (results.length === 1) {
    return [
      `categories.id: subtree("${results[0].id}")`,
      'category',
      { root: results[0], current: results[0] }
    ];
  }

  if (results[0].ancestors.length) {
    return [
      `categories.id: subtree("${results[0].id}")`,
      'category',
      { root: results[1], current: results[0] }
    ];
  }

  const [root, current] = results;

  return [`categories.id: subtree("${current.id}")`, 'category', { root, current }];
};

const filterMap = {
  category: filterByCategory
};

const filterByAttribute = (context: Context, name: string, values: string[]) => {
  const { availableFacets } = context.$ct.config.faceting;
  const { facet, type } = availableFacets.find(f => f.name === name) || {};

  if (!facet || values.length === 0) {
    return [];
  }

  const value = quotedDataTypes.includes(type.toLowerCase())
    ? `"${values.join('","')}"`
    : values.join(',');

  return [`${facet}:${value}`, name, values];
};

export const buildBreadcrumbs = (rootCat) =>
  buildBreadcrumbsList(rootCat, [])
    .reverse()
    .reduce((prev, curr, index) => ([
      ...prev,
      { ...curr, link: `${prev[index - 1]?.link || '' }/${curr.link}` }]),
    []);

export const getFilters = async (context: Context, filters: Record<string, any>) => {
  const filterPromises = Object.entries(filters)
    .map(([name, value]) => {
      if (filterMap[name]) {
        return filterMap[name](context, value);
      }

      return filterByAttribute(context, name, value);
    });

  return (await Promise.all(filterPromises))
    .filter(r => r.length > 0)
    .reduce((p, [facet, name, rawValue]) => ({
      ...p,
      queryFilters: [
        ...p.queryFilters,
        facet
      ],
      rawFilters: {
        ...p.rawFilters,
        [name]: rawValue
      }
    }), { rawFilters: {}, queryFilters: [] });
};

export const getCategories = async (context: Context, { productsResult, rawFilters, sortDirection = 'asc', limit = 50 }) : Promise<any> => {
  if (rawFilters.category) {
    // TODO: `sortDirection` property doesn't seen to be supported
    // @ts-ignore
    const { data: { categories: { results } } } = await context.$ct.api.getFacetCategories({ ancestor: rawFilters.category.root.id, sortDirection, limit });
    return results;
  }

  const facetCategory = productsResult.facets.find(f => f.facet === 'category');

  if (facetCategory.value.terms.length === 0) {
    return { results: [] };
  }

  const catIds = facetCategory.value.terms.map(t => t.term);
  // TODO: `sortDirection` property doesn't seen to be supported
  // @ts-ignore
  const { data: { categories: { results } } } = await context.$ct.api.getFacetCategories({ catIds, sortDirection, limit: catIds.length });

  return results;
};

export const getCategoriesWithCount = (categories: Category[], facets: FacetResultValue[]) : Category[]=> {
  return categories.length > 0
    ? categories.map((c) => {
      const facetCategory = facets.find(f => f.facet === 'category');
      const { terms } = facetCategory.value as TermsFacetResult;
      const categoryTerm = terms.find(t => t.term === c.id);
      const count = categoryTerm?.productCount || categoryTerm?.count || 0;
      return { ...c, count };
    })
    : [];
};

import { productGetters } from './productGetters';
import { createPrice } from './_utils';
import { getCouponsFromCart } from '../helpers/internals';
import type { CartGetters, AgnosticCoupon, AgnosticPrice, AgnosticTotals, AgnosticDiscount } from '@vue-storefront/core';
import type { Cart, InventoryEntry, LineItem } from '@vsf-enterprise/commercetools-types';

function getItems(cart: Cart): LineItem[] {
  if (!cart) {
    return [];
  }

  return cart.lineItems;
}

function getItemName(product: LineItem): string {
  return product?.name || '';
}

function getItemImage(product: LineItem): string {
  return product?.variant?.images[0]?.url || '';
}

function getItemPrice(product: LineItem): AgnosticPrice {
  return createPrice(product);
}

function getItemQty(product: LineItem): number {
  return product?.quantity || 0;
}

function getItemAttributes (product: LineItem, filterByAttributeName?: Array<string>) {
  return productGetters.getAttributes(product.variant, filterByAttributeName);
}

function getItemSku(product: LineItem): string {
  return product?.variant?.sku || '';
}

function getCartSubtotalPrice(cart: Cart, selectSpecialPrices = false): number {
  return getItems(cart).reduce((total, cartItem) => {
    const { special, regular } = getItemPrice(cartItem);
    const itemPrice = (selectSpecialPrices && special) || regular;

    return total + itemPrice;
  }, 0);
}

function getTotals(cart: Cart): AgnosticTotals {
  if (!cart) {
    return {
      total: 0,
      subtotal: 0,
      special: 0
    };
  }

  return {
    total: cart.totalPrice.centAmount / 100,
    subtotal: getCartSubtotalPrice(cart),
    special: getCartSubtotalPrice(cart, true)
  };
}

function getShippingPrice(cart: Cart): number {
  const total = cart?.totalPrice?.centAmount;
  const shippingInfo = cart?.shippingInfo;
  const centAmount = shippingInfo?.shippingMethod?.zoneRates[0].shippingRates[0].freeAbove?.centAmount;

  if (!shippingInfo || !total || (centAmount && total >= centAmount)) {
    return 0;
  }

  return shippingInfo.price.centAmount / 100;
}

function getTotalItems(cart: Cart): number {
  if (!cart) {
    return 0;
  }

  return cart.lineItems.reduce((previous, current) => previous + current.quantity, 0);
}

function getFormattedPrice(price: number): string {
  return price as any as string;
}

function getCoupons(cart: Cart): AgnosticCoupon[] {
  return getCouponsFromCart(cart);
}

// eslint-disable-next-line
function getDiscounts(cart: Cart): AgnosticDiscount[] {
  return [];
}

function getUnavailableProducts(cart: Cart, inventory: InventoryEntry[]) {
  return cart.lineItems.filter((product) => {
    return !inventory.find((inventoryItem) => inventoryItem.sku === product.variant.sku);
  });
}

/**
 * Getter for the `useCart` composable
 */
export const cartGetters: CartGetters<Cart, LineItem> = {
  getTotals,
  getShippingPrice,
  getItems,
  getItemName,
  getItemImage,
  getItemPrice,
  getItemQty,
  getItemAttributes,
  getItemSku,
  getTotalItems,
  getFormattedPrice,
  getCoupons,
  getDiscounts,
  getUnavailableProducts
};

import generateContext from '../helpers/context';
import useShippingProviderMethods from './useShippingProviderMethods';
import { computed } from '@nuxtjs/composition-api';
import { sharedRef, Logger } from '@vue-storefront/core';

import type { Ref } from '@nuxtjs/composition-api';
import type { Context, UseShippingProviderErrors, UseShippingProvider } from '@vue-storefront/core';
import type { ShippingMethod } from '@vsf-enterprise/commercetools-types';
import type { ShippingProviderState } from '../types';

/**
 * Composable for managing shipping addresses for the current order
 */
function useShippingProvider(): UseShippingProvider<ShippingProviderState, ShippingMethod> {
  const context: Context = generateContext(useShippingProviderMethods);
  const loading: Ref<boolean> = sharedRef(false, 'useShippingProvider-loading');
  const state: Ref<ShippingProviderState> = sharedRef(null, 'useShippingProvider-response');
  const error: Ref<UseShippingProviderErrors> = sharedRef({
    load: null,
    save: null
  }, 'useShippingProvider-error');

  /**
   * Sets new state value from params
   */
  function setState(newState: ShippingProviderState) {
    state.value = newState;
    Logger.debug('useShippingProvider.setState', newState);
  }

  /**
   * Saves shipping provider
   */
  async function save({ shippingMethod, customQuery = null }) {
    Logger.debug('useShippingProvider.save');

    try {
      loading.value = true;
      state.value = await useShippingProviderMethods.save(context, { shippingMethod, customQuery, state });
      error.value.save = null;
    } catch (err) {
      error.value.save = err;
      Logger.error('useShippingProvider/save', err);
    } finally {
      loading.value = false;
    }
  }

  /**
   * Loads saved shipping provider
   */
  async function load({ customQuery = null } = {}) {
    Logger.debug('useShippingProvider.load');

    try {
      loading.value = true;
      state.value = await useShippingProviderMethods.load(context, { customQuery, state });
      error.value.load = null;
    } catch (err) {
      error.value.load = err;
      Logger.error('useShippingProvider/load', err);
    } finally {
      loading.value = false;
    }
  }

  return {
    state,
    loading: computed(() => loading.value),
    error: computed(() => error.value),
    load,
    save,
    setState
  };
}

export {
  useShippingProvider
};

import type { AgnosticCoupon } from '@vue-storefront/core';
import type { Cart } from '@vsf-enterprise/commercetools-types';

export function getCouponsFromCart(cart: Cart): AgnosticCoupon[] {
  const coupons = cart?.discountCodes;
  if (!coupons) {
    return;
  }
  return coupons.map(coupon => ({
    id: coupon.discountCode.id,
    name: coupon.discountCode.name,
    code: coupon.discountCode.code,
    value: null,
    state: coupon.state,
    valid: coupon.state === 'MatchesCart'
  }));
}

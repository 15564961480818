import { computed, Ref } from '@nuxtjs/composition-api';
import {
  Context,
  Logger,
  sharedRef,
  ComputedProperty,
  VSF_STORE_COOKIE
} from '@vue-storefront/core';
import generateContext from '../helpers/context';
import useStoreMethods from './useStoreMethods';

import type { StoresData } from '../types';
import {StoresWhereSearch} from "@vsf-enterprise/commercetools-types";

export interface UseStoreErrors {
  load: Error | null;
  change: Error | null;
}

export interface UseStoreInterface<STORES> {
  change(storeKey: string): void;
  load(params?: StoresWhereSearch): Promise<void>;
  store: ComputedProperty<string>;
  loading: ComputedProperty<boolean>;
  response: ComputedProperty<STORES>;
  error: ComputedProperty<UseStoreErrors>;
}

/**
 * Managing stores
 * @returns UseStoreInterface
 * @remarks References:
 * {@link StoresData}
 */
function useStore(): UseStoreInterface<StoresData> {
  const context: Context = generateContext(useStoreMethods);
  const {
    $cookies,
    $ct: { config: { cookies, store: defaultStore } }
  } = context;
  const cookieName: string = cookies?.storeCookieName || VSF_STORE_COOKIE;
  const response: Ref<StoresData> = sharedRef(null, 'useStore-response');
  const loading: Ref<boolean> = sharedRef(false, 'useStore-loading');
  const error: Ref<UseStoreErrors> = sharedRef({ load: null, change: null }, 'useStore-error');
  const store: Ref<string> = sharedRef($cookies.get(cookieName) || defaultStore, 'useStore-store');

  /**
   * Loads available stores
   * @param params - UseStoreFactoryLoadParamArguments
   * @returns Promise<void>
   */
  async function load(params): Promise<void> {
    Logger.debug('useStoreFactory.load', params);

    error.value.load = null;

    try {
      loading.value = true;
      response.value = await useStoreMethods.load(context, params);
    } catch (err) {
      error.value.load = err;
    } finally {
      loading.value = false;
    }
  }

  /**
   * Changes selected store
   * @param storeKey - Stores Unique Identifier
   */
  function change(storeKey: string): void {
    Logger.debug('useStoreFactory.change', storeKey);

    error.value.change = null;

    try {
      loading.value = true;
      $cookies.set(cookieName, storeKey);
      store.value = storeKey;
      window.location.reload();
    } catch (err) {
      error.value.change = err;
    } finally {
      loading.value = false;
    }
  }

  return {
    load,
    change,
    response: computed(() => response.value),
    store: computed(() => store.value),
    loading: computed(() => loading.value),
    error: computed(() => error.value)
  };
}

export {
  useStore
};

import generateContext from '../helpers/context';
import useChannelMethods from './useChannelMethods';
import { Logger, sharedRef, VSF_CHANNEL_COOKIE } from '@vue-storefront/core';
import { computed } from '@nuxtjs/composition-api';

import type { Ref } from '@nuxtjs/composition-api';
import type { Context } from '@vue-storefront/core';
import type { GetChannelParams } from '@vsf-enterprise/commercetools-api';
import type { Channel } from '@vsf-enterprise/commercetools-types';
import type { UseChannel, UseChannelErrors } from '../types';

/**
 * Composable for loading available supply channels
 */
function useChannel(id = ''): UseChannel<Channel[], GetChannelParams> {
  const context: Context = generateContext(useChannelMethods);
  const {
    $cookies,
    $ct: { config: { cookies, channel: defaultChannel } }
  } = context;
  const cookieName: string = cookies?.channelCookieName || VSF_CHANNEL_COOKIE;
  const channel: Ref<string> = sharedRef($cookies.get(cookieName) || defaultChannel, 'useChannel-channel');
  const channels: Ref<Channel[]> = sharedRef([], `useChannel-channels-${id}`);
  const loading: Ref<boolean> = sharedRef(false, `useChannel-loading-${id}`);
  const error: Ref<UseChannelErrors> = sharedRef({
    change: null,
    search: null
  }, `useChannel-error-${id}`);

  /**
   * Searches Channel Entries with given params
   */
  async function search(searchParams: GetChannelParams) {
    Logger.debug(`useChannel/${id}/search`, searchParams);

    try {
      loading.value = true;
      channels.value = await useChannelMethods.searchChannel(context, searchParams);
      error.value.search = null;
    } catch (err) {
      error.value.search = err;
      Logger.error(`useChannel/${id}/search`, err);
    } finally {
      loading.value = false;
    }
  }

  /**
   * Changes selected channel
   * @returns void
   * @param channelId - Channel Unique Identifier
   */
  const change = (channelId: string): void => {
    Logger.debug('useChannel/change', channelId);

    try {
      loading.value = true;
      $cookies.set(cookieName, channelId);
      channel.value = channelId;
      error.value.change = null;
    } catch (err) {
      error.value.change = err;
      Logger.error('useChannel/change', err);
    } finally {
      loading.value = false;
    }
  };

  return {
    search,
    change,
    channels: computed(() => channels.value),
    channel: computed(() => channel.value),
    loading: computed(() => loading.value),
    error: computed(() => error.value)
  };
}

export { useChannel };

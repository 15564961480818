import { useUser } from '../useUser';
import type { UserBillingGetters } from '@vue-storefront/core';
import type { Customer, Address } from '@vsf-enterprise/commercetools-types';

function getAddresses(billing: Partial<Customer>, criteria?: Record<string, any>): Partial<Address>[] {
  if (!billing) {
    return [];
  }

  if (!criteria || !Object.keys(criteria).length) {
    return billing.billingAddresses;
  }

  const entries = Object.entries(criteria);
  return billing.billingAddresses.filter(address => entries.every(([key, value]) => address[key] === value));
}

function getDefault(billing: Partial<Customer>): Partial<Address> {
  return billing.billingAddresses.find(({ id }) => id === billing.defaultBillingAddressId);
}

function getTotal(billing: Partial<Customer>): number {
  return billing.billingAddresses.length;
}

function getPostCode(address: Partial<Address>): string {
  return address?.postalCode || '';
}

function getStreetName(address: Partial<Address>): string {
  return address?.streetName || '';
}

function getStreetNumber(address: Partial<Address>): string {
  return address?.streetNumber || '';
}

function getCity(address: Partial<Address>): string {
  return address?.city || '';
}

function getFirstName(address: Partial<Address>): string {
  return address?.firstName || '';
}

function getLastName(address: Partial<Address>): string {
  return address?.lastName || '';
}

function getCountry(address: Partial<Address>): string {
  return address?.country || '';
}

// TODO: https://github.com/vuestorefront/vue-storefront/issues/4900
function getPhone(address: Partial<Address>): string {
  return address?.phone || '';
}

// TODO: https://github.com/vuestorefront/vue-storefront/issues/4900
function getEmail(address: Partial<Address>): string {
  return address?.email || '';
}

function getProvince(address: Partial<Address>): string {
  return address?.state || '';
}

function getCompanyName(address: Partial<Address>): string {
  return address?.company || '';
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function getTaxNumber(address: Partial<Address>): string {
  return '';
}

function getId(address: Partial<Address>): string {
  return address?.id || '';
}

function getApartmentNumber(address: Partial<Address>): string {
  return address?.apartment || '';
}

function isDefault(address: Partial<Address>): boolean {
  const { user } = useUser();
  return address?.id === user.value.defaultBillingAddressId;
}

/**
 * Getter for the `useUserBilling` composable
 */
export const userBillingGetters: UserBillingGetters<Partial<Customer>, Partial<Address>> = {
  getAddresses,
  getDefault,
  getTotal,
  getPostCode,
  getStreetName,
  getStreetNumber,
  getCity,
  getFirstName,
  getLastName,
  getCountry,
  getPhone,
  getEmail,
  getProvince,
  getCompanyName,
  getTaxNumber,
  getId,
  getApartmentNumber,
  isDefault
};

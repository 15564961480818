import type { Context, CustomQuery } from '@vue-storefront/core';
import type { GetInventoryParams } from '@vsf-enterprise/commercetools-api';
import type { InventoryEntry } from '@vsf-enterprise/commercetools-types';

type UseInventoryMethods<
  INVENTORY,
  INVENTORY_SEARCH_PARAMS
> = {
  searchInventory: (context: Context, params: INVENTORY_SEARCH_PARAMS & { customQuery?: CustomQuery }) => Promise<INVENTORY>;
}

const useInventoryMethods: UseInventoryMethods<InventoryEntry[], GetInventoryParams> = {
  searchInventory: async (context: Context, params: GetInventoryParams): Promise<InventoryEntry[]> => {
    const apiSearchParams = {
      ...params
    };
    const inventory = await context.$ct.api.getInventory(apiSearchParams);
    return inventory.results;
  }
};

export default useInventoryMethods;


import type { Context } from '@vue-storefront/core';
import type { LineItem } from '@vsf-enterprise/commercetools-types';

export const mergeWishlists = async (context: Context) => {
  const anonymousWishlist = context.wishlist.wishlist.value;

  if (!anonymousWishlist) {
    return context.wishlist.load();
  }
  const { data } = await context.$ct.api.getMyShoppingList();
  const userWishlist = data.me.shoppingLists.results[0];

  if (userWishlist.id === anonymousWishlist.id) {
    return context.wishlist.setWishlist(userWishlist);
  }

  const newLineItems = anonymousWishlist.lineItems
    .filter((lineItem: LineItem) => !userWishlist.lineItems.find((li) => li.variant.sku === lineItem.variant.sku))
    .map((lineItem: LineItem) => ({ sku: lineItem.variant.sku }));
  const { data: { wishlist: mergedWishlist } } = await context.$ct.api.addMultipleToMyShoppingList({
    products: newLineItems,
    id: userWishlist.id,
    version: userWishlist.version
  });

  context.wishlist.setWishlist(mergedWishlist);
};


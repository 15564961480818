import { useUser } from '../useUser';

import type { Context, CustomQuery } from '@vue-storefront/core';
import type { Address, Customer } from '@vsf-enterprise/commercetools-types';

type UseUserShippingMethods<USER_SHIPPING, USER_SHIPPING_ITEM> = {
  provide: (context: Context) => any;
  addAddress: (
    context: Context,
    params: {
      address: Readonly<USER_SHIPPING_ITEM>;
      shipping: Readonly<USER_SHIPPING>;
      customQuery?: CustomQuery;
    }) => Promise<USER_SHIPPING>;
  deleteAddress: (
    context: Context,
    params: {
      address: Readonly<USER_SHIPPING_ITEM>;
      shipping: Readonly<USER_SHIPPING>;
      customQuery?: CustomQuery;
    }) => Promise<USER_SHIPPING>;
  updateAddress: (
    context: Context,
    params: {
      address: Readonly<USER_SHIPPING_ITEM>;
      shipping: Readonly<USER_SHIPPING>;
      customQuery?: CustomQuery;
    }) => Promise<USER_SHIPPING>;
  setDefaultAddress: (
    context: Context,
    params: {
      address: Readonly<USER_SHIPPING_ITEM>;
      shipping: Readonly<USER_SHIPPING>;
      customQuery?: CustomQuery;
    }) => Promise<USER_SHIPPING>;
}

async function handler(context, fn, params): Promise<Partial<Customer>> {
  return await fn({ ...params, user: context.user.value }, params.customQuery);
}

// TODO: Add support for custom queries when this is implemented: https://github.com/vuestorefront/vue-storefront/issues/5049
const useUserShippingMethods: UseUserShippingMethods<Partial<Customer>, Partial<Address>> = {
  provide () {
    const { user, load: loadUser, setUser } = useUser();
    return { user, loadUser, setUser } as any;
  },
  addAddress: async (context, params) => handler(context, context.$ct.api.addShippingAddress, params),
  deleteAddress: async (context, params) => handler(context, context.$ct.api.deleteShippingAddress, params),
  updateAddress: async (context, params) => handler(context, context.$ct.api.updateShippingAddress, params),
  setDefaultAddress: async (context, params) => handler(context, context.$ct.api.setDefaultShippingAddress, params)
};

export default useUserShippingMethods;

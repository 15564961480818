import { useVSFContext } from '@vue-storefront/core';
import type { ReviewGetters, AgnosticRateCount } from '@vue-storefront/core';
import type { Review } from '@vsf-enterprise/commercetools-types';
import type { UseReviewData } from '../types';

function getItems(review: UseReviewData): Review[] {
  return review?.results || [];
}

function getReviewId(item: Review): string {
  return item?.id || '';
}

function getReviewAuthor(item: Review): string {
  return item?.authorName || '';
}

function getReviewMessage(item: Review): string {
  return item?.text || '';
}

function getReviewRating(item: Review): number {
  return item?.rating || 0;
}

function getReviewDate(item: Review): string {
  if (!item?.createdAt) {
    return '';
  }

  const { $ct: { config: { acceptLanguage } }} = useVSFContext();

  const date = new Date(item.createdAt);

  return new Intl.DateTimeFormat(acceptLanguage).format(date);
}

function getTotalReviews(review: UseReviewData): number {
  return review?.total || 0;
}

function getAverageRating(review: UseReviewData): number {
  return review?.averageRating || 0;
}

function getRatesCount(review: UseReviewData): AgnosticRateCount[] {
  const rates = review?.ratingsDistribution || {};

  return Object
    .entries(rates)
    .map(([rate, count]) => ({
      rate: Number(rate),
      count: Number(count)
    }));
}

function getReviewsPage(review: UseReviewData): number {
  const limit = review?.limit || 0;
  const offset = review?.offset || 0;

  if (!limit) {
    return 1;
  }

  return (offset / limit) + 1;
}

/**
 * Getter for the `useReview` composable
 */
export const reviewGetters: ReviewGetters<UseReviewData, Review> = {
  getItems,
  getReviewId,
  getReviewAuthor,
  getReviewMessage,
  getReviewRating,
  getReviewDate,
  getTotalReviews,
  getAverageRating,
  getRatesCount,
  getReviewsPage
};

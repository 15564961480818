import generateContext from '../helpers/context';
import useWishlistMethods from './useWishlistMethods';
import { computed } from '@nuxtjs/composition-api';
import { Logger, sharedRef } from '@vue-storefront/core';

import type { Ref } from '@nuxtjs/composition-api';
import type { Context, UseWishlist, UseWishlistErrors } from '@vue-storefront/core';
import type { ShoppingList, LineItem, ProductVariant } from '@vsf-enterprise/commercetools-types';

/**
 * Composable for managing user wishlist
 */
export function useWishlist(): UseWishlist<Partial<ShoppingList>, LineItem, ProductVariant> {
  const context: Context = generateContext(useWishlistMethods);
  const loading: Ref<boolean> = sharedRef<boolean>(false, 'useWishlist-loading');
  const wishlist: Ref<Partial<ShoppingList>> = sharedRef(null, 'useWishlist-wishlist');
  const error: Ref<UseWishlistErrors> = sharedRef({
    addItem: null,
    removeItem: null,
    load: null,
    clear: null
  }, 'useWishlist-error');

  /**
   * Sets new wishlist
   */
  function setWishlist(newWishlist: ShoppingList) {
    wishlist.value = newWishlist;
    Logger.debug('useWishlistFactory.setWishlist', newWishlist);
  }

  /**
   * Adds product to wishlist
   */
  async function addItem({ product, customQuery }) {
    Logger.debug('useWishlist.addItem', product);

    try {
      loading.value = true;
      const updatedWishlist = await useWishlistMethods.addItem(context, {
        currentWishlist: wishlist.value,
        product,
        customQuery
      });
      error.value.addItem = null;
      wishlist.value = updatedWishlist;
    } catch (err) {
      error.value.addItem = err;
      Logger.error('useWishlist/addItem', err);
    } finally {
      loading.value = false;
    }
  }

  /**
   * Removes product from wishlist
   */
  async function removeItem({ product, customQuery }) {
    Logger.debug('useWishlist.removeItem', product);

    try {
      loading.value = true;
      const updatedWishlist = await useWishlistMethods.removeItem(context, {
        currentWishlist: wishlist.value,
        product,
        customQuery
      });
      error.value.removeItem = null;
      wishlist.value = updatedWishlist;
    } catch (err) {
      error.value.removeItem = err;
      Logger.error('useWishlist/removeItem', err);
    } finally {
      loading.value = false;
    }
  }

  /**
   * Loads current wishlist
   */
  async function load({ customQuery } = { customQuery: undefined }) {
    Logger.debug('useWishlist.load');
    if (wishlist.value) return;

    try {
      loading.value = true;
      wishlist.value = await useWishlistMethods.load(context, { customQuery });
      error.value.load = null;
    } catch (err) {
      error.value.load = err;
      Logger.error('useWishlist/load', err);
    } finally {
      loading.value = false;
    }
  }

  /**
   * Clears wishlist from products
   */
  async function clear() {
    Logger.debug('useWishlist.clear');

    try {
      loading.value = true;
      const updatedWishlist = await useWishlistMethods.clear(context, {
        currentWishlist: wishlist.value
      });
      error.value.clear = null;
      wishlist.value = updatedWishlist;
    } catch (err) {
      error.value.clear = err;
      Logger.error('useWishlist/clear', err);
    } finally {
      loading.value = false;
    }
  }

  /**
   * Checks if product is in the wishlist
   */
  function isInWishlist({ product }) {
    Logger.debug('useWishlist.isInWishlist', product);

    return useWishlistMethods.isInWishlist(context, {
      currentWishlist: wishlist.value,
      product
    });
  }

  return {
    wishlist: computed(() => wishlist.value),
    isInWishlist,
    addItem,
    load,
    removeItem,
    clear,
    setWishlist,
    loading: computed(() => loading.value),
    error: computed(() => error.value)
  };
}

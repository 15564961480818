import { useVSFContext } from '@vue-storefront/core';
import type { Context } from '@vue-storefront/core';

function generateContext(methods: any): Context {
  const vsfContext = useVSFContext();

  return {
    ...vsfContext.$vsf,
    ...(methods.provide && { ...methods.provide(vsfContext.$vsf) }),
    ...(vsfContext.$cookies && { $cookies: vsfContext.$cookies })
  };
}

export default generateContext;

import useBillingMethods from './useBillingMethods';
import generateContext from '../helpers/context';
import { computed } from '@nuxtjs/composition-api';
import { sharedRef, UseBillingErrors, Logger } from '@vue-storefront/core';

import type { Ref } from '@nuxtjs/composition-api';
import type { Context, UseBilling } from '@vue-storefront/core';
import type { Address } from '@vsf-enterprise/commercetools-types';

/**
 * Composable for managing billing addresses for the current order
 */
function useBilling(): UseBilling<Address, any> {
  const context: Context = generateContext(useBillingMethods);
  const loading: Ref<boolean> = sharedRef(false, 'useBilling-loading');
  const billing: Ref<Address> = sharedRef(null, 'useBilling-billing');
  const error: Ref<UseBillingErrors> = sharedRef({
    load: null,
    save: null
  }, 'useBilling-error');

  /**
   * Loads saved billing info
   */
  async function load({ customQuery = null } = {}) {
    Logger.debug('useBilling.load');

    try {
      loading.value = true;
      const billingInfo = await useBillingMethods.load(context, { customQuery });
      error.value.load = null;
      billing.value = billingInfo;
    } catch (err) {
      error.value.load = err;
      Logger.error('useBilling/load', err);
    } finally {
      loading.value = false;
    }
  }

  /**
   * Saves billing info
   */
  async function save(saveParams) {
    Logger.debug('useBilling.save');

    try {
      loading.value = true;
      const billingInfo = await useBillingMethods.save(context, saveParams);
      error.value.save = null;
      billing.value = billingInfo;
    } catch (err) {
      error.value.save = err;
      Logger.error('useBilling/save', err);
    } finally {
      loading.value = false;
    }
  }

  return {
    billing: computed(() => billing.value),
    loading: computed(() => loading.value),
    error: computed(() => error.value),
    load,
    save
  };
}

export {
  useBilling
};

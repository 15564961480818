import generateContext from '../helpers/context';
import useUserBillingMethods from './useUserBillingMethods';
import { computed, unref } from '@nuxtjs/composition-api';
import { Logger, sharedRef } from '@vue-storefront/core';
import { useUser } from '../useUser';

import type { Ref } from '@nuxtjs/composition-api';
import type { Context, UseUserBilling, UseUserBillingErrors } from '@vue-storefront/core';
import type { Address, Customer } from '@vsf-enterprise/commercetools-types';

/**
 * Composable for managing user billing addresses
 */
function useUserBilling(): UseUserBilling<Partial<Customer>, Partial<Address>> {
  const { user, setUser } = useUser();

  const billing: Ref<Partial<Customer>> = user;
  const context: Context = generateContext(useUserBillingMethods);
  const loading: Ref<boolean> = sharedRef(false, 'useUserBilling-loading');
  const error: Ref<UseUserBillingErrors> = sharedRef({
    addAddress: null,
    deleteAddress: null,
    updateAddress: null,
    load: null,
    setDefaultAddress: null
  }, 'useUserBilling-error');

  const readonlyBilling: Readonly<Partial<Customer>> = unref(billing);

  /**
   * Adds user billing address
   */
  async function addAddress({ address, customQuery }) {
    Logger.debug('useUserBilling.addAddress', address);

    try {
      loading.value = true;
      setUser(await useUserBillingMethods.addAddress(context, {
        address,
        billing: readonlyBilling,
        customQuery
      }));
      error.value.addAddress = null;
    } catch (err) {
      error.value.addAddress = err;
      Logger.error('useUserBilling/addAddress', err);
    } finally {
      loading.value = false;
    }
  }

  /**
   * Deletes user billing address
   */
  async function deleteAddress({ address, customQuery }) {
    Logger.debug('useUserBilling.deleteAddress', address);

    try {
      loading.value = true;
      setUser(await useUserBillingMethods.deleteAddress(context, {
        address,
        billing: readonlyBilling,
        customQuery
      }));
      error.value.deleteAddress = null;
    } catch (err) {
      error.value.deleteAddress = err;
      Logger.error('useUserBilling/deleteAddress', err);
    } finally {
      loading.value = false;
    }
  }

  /**
   * Updates user billing address
   */
  async function updateAddress({ address, customQuery }) {
    Logger.debug('useUserBilling.updateAddress', address);

    try {
      loading.value = true;
      setUser(await useUserBillingMethods.updateAddress(context, {
        address,
        billing: readonlyBilling,
        customQuery
      }));
      error.value.updateAddress = null;
    } catch (err) {
      error.value.updateAddress = err;
      Logger.error('useUserBilling/updateAddress', err);
    } finally {
      loading.value = false;
    }
  }

  /**
   * Loads saved user billing address
   */
  async function load() {
    console.warn('useUserBilling.load method is deprecatd. It will be removed in one of the future releases.');
  }

  /**
   * Sets default user billing address
   */
  async function setDefaultAddress({ address, customQuery }) {
    Logger.debug('useUserBilling.setDefaultAddress');

    try {
      loading.value = true;
      setUser(await useUserBillingMethods.setDefaultAddress(context, {
        address,
        billing: readonlyBilling,
        customQuery
      }));
      error.value.setDefaultAddress = null;
    } catch (err) {
      error.value.setDefaultAddress = err;
      Logger.error('useUserBilling/setDefaultAddress', err);
    } finally {
      loading.value = false;
    }
  }

  return {
    billing: computed(() => billing.value),
    loading: computed(() => loading.value),
    error: computed(() => error.value),
    addAddress,
    deleteAddress,
    updateAddress,
    load,
    setDefaultAddress
  };
}

export {
  useUserBilling
};

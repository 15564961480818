import { formatAttributeList, getVariantByAttributes, createPrice } from './_utils';
import type { ProductGetters, AgnosticMediaGalleryItem, AgnosticAttribute, AgnosticPrice, AgnosticBreadcrumb } from '@vue-storefront/core';
import type { ProductVariant, Image } from '@vsf-enterprise/commercetools-types';

export interface ProductGettersFilters {
  master?: boolean;
  attributes?: Record<string, string>;
  skus?: string[]
}

function getName(product: ProductVariant | Readonly<ProductVariant>): string {
  return (product as any)?._name || '';
}

function getSlug(product: ProductVariant | Readonly<ProductVariant>): string {
  return (product as any)?._slug || '';
}

function getPrice(product: ProductVariant | Readonly<ProductVariant>): AgnosticPrice {
  return createPrice(product);
}

function getGallery(product: ProductVariant): AgnosticMediaGalleryItem[] {
  const images = product?.images || [];

  return images.map((image: Image) => ({
    small: image.url,
    big: image.url,
    normal: image.url
  }));
}

function getCoverImage(product: ProductVariant): string {
  return product?.images?.[0]?.url || '';
}

function getFiltered(products: ProductVariant[], filters: ProductGettersFilters | any = {}): ProductVariant[] {
  if (!products) {
    return [];
  }

  if (filters.skus) {
    return products.filter(product => filters.skus.includes(product.sku));
  }

  if (filters.attributes && Object.keys(filters.attributes).length > 0) {
    return [getVariantByAttributes(products, filters.attributes)];
  }

  if (filters.master) {
    return products.filter((product) => (product as any)._master);
  }

  return products;
}

function getAttributes(products: ProductVariant[] | ProductVariant, filterByAttributeName?: string[]): Record<string, AgnosticAttribute | string> {
  const isSingleProduct = !Array.isArray(products);
  const productList = (isSingleProduct ? [products] : products) as ProductVariant[];

  if (!products || productList.length === 0) {
    return {} as any;
  }

  const formatAttributes = (product: ProductVariant): AgnosticAttribute[] =>
    formatAttributeList(product.attributesRaw).filter((attribute) => filterByAttributeName ? filterByAttributeName.includes(attribute.name) : attribute);

  const reduceToUniques = (prev, curr) => {
    const isAttributeExist = prev.some((el) => el.name === curr.name && el.value === curr.value);

    if (!isAttributeExist) {
      return [...prev, curr];
    }

    return prev;
  };

  const reduceByAttributeName = (prev, curr) => ({
    ...prev,
    [curr.name]: isSingleProduct ? curr.value : [
      ...(prev[curr.name] || []),
      {
        value: curr.value,
        label: curr.label
      }
    ]
  });

  return productList
    .map((product) => formatAttributes(product))
    .reduce((prev, curr) => [...prev, ...curr], [])
    .reduce(reduceToUniques, [])
    .reduce(reduceByAttributeName, {});
}

function getDescription(product: ProductVariant): any {
  return (product as any)?._description || '';
}

function getCategoryIds(product: ProductVariant): string[] {
  return (product as any)?._categoriesRef || '';
}

function getCategorySlugs(product: ProductVariant): string[] {
  return (product as any)?._categories?.map((category) => category.slug) || [];
}

function getId(product: ProductVariant): string {
  return (product as any)?._id || '';
}

function getFormattedPrice(price: number) {
  return price as any as string;
}

function getBreadcrumbs(product: Record<string, any>): AgnosticBreadcrumb[] {
  if (!product) {
    return [];
  }

  const topCategory = product?._original?.masterData?.current?.categories[0];
  if (!topCategory) {
    return [];
  }

  const buildBreadcrumbsList = ({ parent }, bc) => {
    const newBc = [...bc, { text: parent?.name, link: parent?.slug }];
    return parent ? buildBreadcrumbsList(parent, newBc) : newBc;
  };

  const buildBreadcrumbs = buildBreadcrumbsList(topCategory, [])
    .reverse()
    .filter(breadcrumb => breadcrumb?.text || breadcrumb?.link);

  const breadcrumbs = [
    ...buildBreadcrumbs,
    { text: topCategory.name, link: topCategory.slug }
  ]
    .map(b => ({
      text: b.text, link: buildBreadcrumbs[0].link !== b.link ? `/c/${buildBreadcrumbs[0].link}/${b.link}` : `/c/${b.link}`
    }));

  return [
    { text: 'Home', link: '/' },
    ...breadcrumbs,
    { text: product._name, link: `/p/${product._slug}/${product.sku}` }
  ];
}

function getTotalReviews(product: ProductVariant): number {
  return (product as any)?._rating?.count || 0;
}

function getAverageRating(product: ProductVariant): number {
  return (product as any)?._rating?.averageRating || 0;
}

function getSku(product: ProductVariant): any {
  return (product as any)?.sku || '';
}

/**
 * Getter for the `useProduct` composable
 */
export const productGetters: ProductGetters<ProductVariant, ProductGettersFilters> = {
  getName,
  getSlug,
  getPrice,
  getGallery,
  getCoverImage,
  getFiltered,
  getAttributes,
  getDescription,
  getCategoryIds,
  getCategorySlugs,
  getId,
  getSku,
  getFormattedPrice,
  getTotalReviews,
  getAverageRating,
  getBreadcrumbs
};

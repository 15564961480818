import type { Context } from '@vue-storefront/core';
import type { Cart } from '@vsf-enterprise/commercetools-types';

const loadCurrentCart = async (context: Context, customQueryFn = (user = null, cart = null) => ({ cart, user })): Promise<Partial<Cart>> => {
  const { user, cart } = customQueryFn();
  const { data: profileData } = await context.$ct.api.getMe({ customer: false }, user);

  if (profileData.me.activeCart) {
    return profileData.me.activeCart;
  }

  const { data } = await context.$ct.api.createCart({}, cart);

  return data.cart;
};

export default loadCurrentCart;

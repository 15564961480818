import type {
  Composable,
  ComposableFunctionArgs,
  ComputedProperty,
  CustomQuery,
  FacetSearchResult,
  PlatformApi
} from '@vue-storefront/core';

import type {
  Filter,
  CustomerCreatePasswordResetTokenResponse,
  CustomerResetPasswordResponse,
  GetReviewResponse
} from '@vsf-enterprise/commercetools-api';

import type {
  Category,
  FacetResultValue,
  ResourceIdentifierInput,
  ShippingInfo,
  ShoppingList,
  Store,
  ProductProjection,
  ReviewRatingStatistics,
  AttributeDefinition
} from '@vsf-enterprise/commercetools-types';
import { StoreQueryResult } from '@vsf-enterprise/commercetools-types/src/Schema';

/**
 * Faceting filtering strategies supported by the commercetools
 */
export enum FacetFilteringStrategy {
  Filter = 'filter',
  Query = 'query',
  Facets = 'facets'
}

/**
 * Facet types supported by the commercetools
 */
export enum FacetType {
  String = 'string',
  Date = 'date',
  Time = 'time',
  DateTime = 'datetime',
  Boolean = 'boolean',
  Number = 'number'
}

/**
 * Sorting directions supported by the commercetools
 */
export enum SortingDirection {
  Asc = 'asc',
  Desc = 'desc'
}

/**
 * Filters available to the user
 */
export interface ConfigFacet {

  /**
   * Facet expressions described on {@link https://docs.commercetools.com/api/projects/products-search#termfacetexpression | this page}
   */
  facet: string;

  /**
   * Facet data type
   */
  type: FacetType;

  /**
   * Filtering options described on {@link https://docs.commercetools.com/api/projects/products-search#filters | this page}
   */
  option: string;

  /**
   * Facet alias described on {@link https://docs.commercetools.com/api/projects/products-search#alias | this page}.
   * The `category` alias for the first facet shown above is a constant and shouldn't be changed
   */
  name: string;

  /**
   * Scope(s) applied to this specific filter. For more information refer to {@link https://docs.commercetools.com/api/projects/products-search#filters | this page}
   */
  filteringStrategy?: FacetFilteringStrategy | FacetFilteringStrategy[];

  /**
   * Whether to return product item count instead of default variant count. For more information refer to {@link https://docs.commercetools.com/api/projects/products-search#counting-products | this page}
   */
  countingProducts?: boolean;
}

/**
 * Country configuration object
 */
export interface ConfigCountry {
  name: string;
  locale: string;
  states?: string[];
}

/**
 * Currency configuration object
 */
export interface ConfigCurrency {
  name: string;
  label: string;
}

/**
 * Language configuration object
 */
export interface ConfigLocale {
  name: string;
  label: string;
}

/**
 * Sorting options available to the user
 */
export interface ConfigSortingOptions {

  /**
   * Unique identifier
   */
  id: string;

  /**
   * Label displayed to the user
   */
  name: string;

  /**
   * Name of the field to sort by. For more information refer to {@link https://docs.commercetools.com/api/projects/products-search#sorting | this page}
   */
  facet: string;

  /**
   * Sorting direction
   */
  direction: SortingDirection;
}

/**
  * Search options available to the user, such as pagination, sorting and available filters
  */
export interface ConfigFacetingOptions {

  /**
   * Number of elements that can be displayed per page
   */
  pageOptions: number[];

  /**
   * The maximum number of subcategories displayed for any given category.
   */
  subcategoriesLimit: number;

  /**
   * Filters available to the user
   */
  availableFacets: ConfigFacet[];

  /**
   * Sorting options available to the user
   */
  sortingOptions: ConfigSortingOptions[];

  /**
   * Fallback scope applied to the facets that don't have strategy defined
   */
  filteringStrategy: FacetFilteringStrategy;
}

/**
 * Configuration object for the `@vsf-enterprise/commercetools/nuxt`
 * module in the `nuxt.config.js` file
 */
export interface Config {

  /**
   * Internationalization configuration
   */
  i18n: {

    /**
     * Indicates whether to use global`i18n` configuration in the `nuxt.config.js`
     * or configuration from this object instead
     */
    useNuxtI18nConfig: boolean;
  };

  /**
   * Controls search options available to the user, such as
   * pagination, sorting and available filters
   */
  faceting: ConfigFacetingOptions;

  /**
   * Default language. Used only if `useNuxtI18nConfig: false`
   */
  locale?: string;

  /**
   * Default currency. Used only if `useNuxtI18nConfig: false`
   */
  currency?: string;

  /**
   * Default country. Used only if `useNuxtI18nConfig: false`
   */
  country?: string;

  /**
   * Default store. Used only if `useNuxtI18nConfig: false`
   */
  store?: string;

  /**
   * Default store. Used only if `useNuxtI18nConfig: false`
   */
  channel?: string;

  /**
   * List of supported language codes. Used only if `useNuxtI18nConfig: false`
   */
  acceptLanguage?: string[];

  /**
   * List of supported countries. Used only if `useNuxtI18nConfig: false`
   */
  countries?: ConfigCountry[];

  /**
   * List of supported currencies. Used only if `useNuxtI18nConfig: false`
   */
  currencies?: ConfigCurrency[];

  /**
   * List of supported languages. Used only if `useNuxtI18nConfig: false`
   */
  locales?: ConfigLocale[];

  /**
   * Whether a store supports multiple currencies
   */
  enableMultiCurrency: boolean;

  /**
   * Whether a store supports Click & Collect
   */
  enableClickCollect: boolean;

  /**
   * Whether filter products by the selected channel
   */
  enableChannelFilter: boolean;

  /**
   * Whether a checkout has an additional step for guest
   */
   enableCustomerCheckoutStep: boolean;

  /**
   * Configurable Click&Collect shipping method name
   */
  collectShippingKey: string;

  /**
   * Name of the cookies containing internationalization options. Used only if `useNuxtI18nConfig: false`
   */
  cookies?: {

    /**
     * Language cookie. Used only if `useNuxtI18nConfig: false`
     */
     localeCookieName?: string;

    /**
     * Country cookie. Used only if `useNuxtI18nConfig: false`
     */
     countryCookieName?: string;

    /**
     * Currency cookie. Used only if `useNuxtI18nConfig: false`
     */
     currencyCookieName?: string;

    /**
     * Store cookie. Used only if `useNuxtI18nConfig: false`
     */
     storeCookieName?: string;

    /**
     * Channel cookie. Used only if `useNuxtI18nConfig: false`
     */
     channelCookieName?: string;
  }
}

export type OrderSearchParams = {
  id?: string;
  page?: number;
  perPage?: number;
};

export interface ProductsSearchParams {
  perPage?: number;
  page?: number;
  sort?: any;
  term?: any;
  filters?: Filter[];
  catId?: string | string[];
  skus?: string[];
  slug?: string;
  id?: string;
  ids?: string[];
  customFilters?: string;
  key?: string;
}

export interface FacetResultsData {
  results: ProductProjection[];
  categories: Category[];
  attributeDefinitions: AttributeDefinition[];
  facets: FacetResultValue[];
  rootCategory: Category;
  currentCategory: Category;
  total: number;
}

export interface ForgotPasswordResult {
  resetPasswordResult: CustomerCreatePasswordResetTokenResponse;
  setNewPasswordResult: CustomerResetPasswordResponse;
}

export interface ForgotPasswordRequestExtend { request(params: ComposableFunctionArgs<{ email: string, expose?: boolean }>): Promise<void> }

export interface SetNewPasswordParams {
  tokenValue: string;
  newPassword: string;
}

export interface ResetPasswordParams {
  email: string;
  expose: boolean;
}

export type SearchData = FacetSearchResult<FacetResultsData>

export type StoresData = {
  stores: StoreQueryResult,
  _selectedStore: string;
};

export interface StoreGetters<STORES, CRITERIA = any> {
  getItems(stores: STORES, criteria?: CRITERIA): Store[];
  getSelected(stores: STORES): Store | undefined;
}

export interface UseStoreFactoryChangeChannelParamArguments {
  channel: Record<string, ResourceIdentifierInput>;
  customQuery?: CustomQuery;
}

/**
 * @deprecated Use `ShoppingList` from the `@vsf-enterprise/commercetools-type` package instead
 */
export type Wishlist = ShoppingList;

export type ShippingProviderState = {
  response: ShippingInfo
}

export interface UseInventoryErrors {
  search: Error;
  addReview: Error;
}

export interface UseInventory<INVENTORY, INVENTORY_SEARCH_PARAMS, API extends PlatformApi = any> extends Composable<API> {
  search(params: ComposableFunctionArgs<INVENTORY_SEARCH_PARAMS>): Promise<void>;
  error: ComputedProperty<UseInventoryErrors>;
  inventory: ComputedProperty<INVENTORY>;
  loading: ComputedProperty<boolean>;
  [x: string]: any;
}

export interface UseChannelErrors {
  change: Error;
  search: Error;
}

export interface UseChannel<CHANNEL, CHANNEL_SEARCH_PARAMS, API extends PlatformApi = any> extends Composable<API> {
  search(params: ComposableFunctionArgs<CHANNEL_SEARCH_PARAMS>): Promise<void>;
  change(params: string): void;
  channels: ComputedProperty<CHANNEL>;
  channel: ComputedProperty<string>;
  error: ComputedProperty<UseChannelErrors>;
  loading: ComputedProperty<boolean>;
  [x: string]: any;
}

/**
 * Data returned from the `search` and `addReview` methods from `useReview` composable
 */
export type UseReviewData = GetReviewResponse & Partial<ReviewRatingStatistics>;

export type TransformedPrice = {
  regular: string;
  special?: string;
}

export interface UseShippingErrors {
  load: Error;
  save: Error;
  clear: Error;
  saveCollectable: Error;
}

export interface UseShipping<SHIPPING, SHIPPING_PARAMS, API extends PlatformApi = any> extends Composable<API> {
  error: ComputedProperty<UseShippingErrors>;
  loading: ComputedProperty<boolean>;
  shipping: ComputedProperty<SHIPPING>;
  load(): Promise<void>;
  load(params: {
    customQuery?: CustomQuery;
  }): Promise<void>;
  save: (params: {
    params: SHIPPING_PARAMS;
    shippingDetails: SHIPPING;
    customQuery?: CustomQuery;
  }) => Promise<void>;
  clear: (params: {
    customQuery?: CustomQuery;
  }) => Promise<void>;
  saveCollectable: (params: {
    params: SHIPPING_PARAMS;
    shippingDetails: SHIPPING;
    customQuery?: CustomQuery;
  }) => Promise<void>;
}

export interface ErrorResponse {
  graphQLErrors?: {
    message: string;
    code: string;
  }[];
  clientErrors?: string[];
  networkError?: string | null;
  message?: string;
  simple?: boolean;
}

export interface CustomErrorResponse {
  message: string
}

export type ErrorHandling = (errorResponse: ErrorResponse, customError?: CustomErrorResponse) => void

export interface UseCartErrors {
  addItem: Error;
  removeItem: Error;
  updateItemQty: Error;
  load: Error;
  clear: Error;
  applyCoupon: Error;
  removeCoupon: Error;
  setItemSupplyChannel: Error;
}

export interface UseCart<CART, CART_ITEM, PRODUCT, API extends PlatformApi = any> extends Composable<API> {
  cart: ComputedProperty<CART>;
  setCart(cart: CART): void;
  addItem(params: {
    product: PRODUCT;
    quantity: number;
    supplyChannel?: string,
    distributionChannel?: string,
    customQuery?: CustomQuery;
  }): Promise<void>;
  isInCart: ({ product: PRODUCT }: {
    product: any;
  }) => boolean;
  removeItem(params: {
    product: CART_ITEM;
    customQuery?: CustomQuery;
  }): Promise<void>;
  updateItemQty(params: {
    product: CART_ITEM;
    quantity?: number;
    customQuery?: CustomQuery;
  }): Promise<void>;
  clear(): Promise<void>;
  applyCoupon(params: {
    couponCode: string;
    customQuery?: CustomQuery;
  }): Promise<void>;
  removeCoupon(params: {
    couponCode: string;
    customQuery?: CustomQuery;
  }): Promise<void>;
  load(): Promise<void>;
  load(params: {
    customQuery?: CustomQuery;
    reload?: boolean
  }): Promise<void>;
  setItemSupplyChannel(params: {
    product: CART_ITEM;
    customQuery?: CustomQuery;
  }): Promise<void>
  error: ComputedProperty<UseCartErrors>;
  loading: ComputedProperty<boolean>;
}

import { catchApiErrors } from '../helpers/internals/handleApiErrors';

import type { Context } from '@vue-storefront/core';
import type { OrderQueryResult } from '@vsf-enterprise/commercetools-types';
import type { OrderSearchParams, ErrorResponse } from '../types';
import type { CustomQuery } from '@vsf-enterprise/commercetools-api';

type UseUserOrderMethods<ORDERS, ORDER_SEARCH_PARAMS> = {
  searchOrders: (context: Context, params: ORDER_SEARCH_PARAMS & { customQuery?: CustomQuery }) => Promise<ORDERS>;
};

const useUserOrderMethods: UseUserOrderMethods<OrderQueryResult, OrderSearchParams> = {
  searchOrders: async (context: Context, { customQuery, ...searchParams } = {}): Promise<OrderQueryResult> => {
    const response = await context.$ct.api.getOrders(searchParams, customQuery);
    catchApiErrors(response as unknown as ErrorResponse);

    return response.data?.me.orders || { results: [], total: 0, offset: 0, count: 0, exists: false };
  }
};

export default useUserOrderMethods;

import type { Context, CustomQuery } from '@vue-storefront/core';
import type { GetChannelParams } from '@vsf-enterprise/commercetools-api';
import type { Channel } from '@vsf-enterprise/commercetools-types';

type UseChannelMethods<
  CHANNEL,
  CHANNEL_SEARCH_PARAMS> = {
  searchChannel: (context: Context, params: CHANNEL_SEARCH_PARAMS & { customQuery?: CustomQuery }) => Promise<CHANNEL>;
}

const useChannelMethods: UseChannelMethods<Channel[], GetChannelParams> = {
  searchChannel: async (context: Context, params): Promise<Channel[]> => {
    const apiSearchParams = {
      ...params
    };
    const channels = await context.$ct.api.getChannel(apiSearchParams);
    return channels.results;
  }
};

export default useChannelMethods;

import useUserMethods from './useUserMethods';
import generateContext from '../helpers/context';
import { computed } from '@nuxtjs/composition-api';
import { Logger, mask, sharedRef } from '@vue-storefront/core';

import type { Ref } from '@nuxtjs/composition-api';
import type { UseUser, UseUserErrors } from '@vue-storefront/core';
import type { Customer } from '@vsf-enterprise/commercetools-types';

/**
 * Composable for managing current user
 */
function useUser(): UseUser<Partial<Customer>, any> {
  const context = generateContext(useUserMethods);
  const errorsFactory = (): UseUserErrors => ({
    updateUser: null,
    register: null,
    login: null,
    logout: null,
    changePassword: null,
    load: null
  });

  const user: Ref<Partial<Customer>> = sharedRef(null, 'useUser-user');
  const loading: Ref<boolean> = sharedRef(false, 'useUser-loading');
  const isAuthenticated = computed(() => Boolean(user.value));
  const error: Ref<UseUserErrors> = sharedRef(errorsFactory(), 'useUser-error');

  /**
   * Sets user info
   */
  function setUser(newUser: Customer) {
    user.value = newUser;
    Logger.debug('useUserFactory.setUser', newUser);
  }

  function resetErrorValue() {
    error.value = errorsFactory();
  }

  /**
   * Updates user info
   */
  async function updateUser({ user: providedUser, customQuery }) {
    Logger.debug('useUserFactory.updateUser', providedUser);
    resetErrorValue();

    try {
      loading.value = true;
      user.value = await useUserMethods.updateUser(context, {currentUser: user.value, updatedUserData: providedUser, customQuery});
      error.value.updateUser = null;
    } catch (err) {
      error.value.updateUser = err;
      Logger.error('useUser/updateUser', err);
    } finally {
      loading.value = false;
    }
  }

  /**
   * Registers new user
   */
  async function register({ user: providedUser, customQuery }) {
    Logger.debug('useUserFactory.register', providedUser);
    resetErrorValue();

    try {
      loading.value = true;
      user.value = await useUserMethods.register(context, {...providedUser, customQuery});
      error.value.register = null;
    } catch (err) {
      error.value.register = err;
      Logger.error('useUser/register', err);
    } finally {
      loading.value = false;
    }
  }

  /**
   * Login user
   */
  async function login({ user: providedUser, customQuery }) {
    Logger.debug('useUserFactory.login', providedUser);
    resetErrorValue();

    try {
      loading.value = true;
      user.value = await useUserMethods.logIn(context, {...providedUser, customQuery});
      error.value.login = null;
    } catch (err) {
      error.value.login = err;
      Logger.error('useUser/login', err);
    } finally {
      loading.value = false;
    }
  }

  /**
   * Logout user
   */
  async function logout() {
    Logger.debug('useUserFactory.logout');
    resetErrorValue();

    try {
      await useUserMethods.logOut(context, { currentUser: user.value });
      error.value.logout = null;
      user.value = null;
    } catch (err) {
      error.value.logout = err;
      Logger.error('useUser/logout', err);
    }
  }

  /**
   * Changes user password
   */
  async function changePassword(params) {
    Logger.debug('useUserFactory.changePassword', { currentPassword: mask(params.current), newPassword: mask(params.new) });
    resetErrorValue();

    try {
      loading.value = true;
      user.value = await useUserMethods.changePassword(context, {
        currentUser: user.value,
        currentPassword: params.current,
        newPassword: params.new,
        customQuery: params.customQuery
      });
      error.value.changePassword = null;
    } catch (err) {
      error.value.changePassword = err;
      Logger.error('useUser/changePassword', err);
    } finally {
      loading.value = false;
    }
  }

  /**
   * Loads user info
   */
  async function load({customQuery} = {customQuery: undefined}) {
    Logger.debug('useUserFactory.load');
    resetErrorValue();

    try {
      loading.value = true;
      user.value = await useUserMethods.load(context, { customQuery });
      error.value.load = null;
    } catch (err) {
      error.value.load = err;
      Logger.error('useUser/load', err);
    } finally {
      loading.value = false;
    }
  }

  return {
    setUser,
    user: computed(() => user.value),
    updateUser,
    register,
    login,
    logout,
    isAuthenticated,
    changePassword,
    load,
    loading: computed(() => loading.value),
    error: computed(() => error.value)
  };
}

export {
  useUser
};

import { useCart } from '../useCart';
import { catchApiErrors } from '../helpers/internals/handleApiErrors';
import { ErrorResponse, UseCart } from '../types';
import { cartActions, CustomQuery } from '@vsf-enterprise/commercetools-api';

import type { Context } from '@vue-storefront/core';
import type { Address, Cart, LineItem, ProductVariant } from '@vsf-enterprise/commercetools-types';

type UseShippingMethods<SHIPPING, SHIPPING_PARAMS> = {
  provide: (context: Context) => { cart: UseCart<Partial<Cart>, LineItem, ProductVariant, any> };
  load: (context: Context, params: { customQuery?: CustomQuery }) => Promise<SHIPPING>;
  save: (context: Context, params: { params: SHIPPING_PARAMS; shippingDetails: SHIPPING; customQuery?: CustomQuery }) => Promise<SHIPPING>;
  clear: (context: Context, params: { customQuery?: CustomQuery }) => Promise<SHIPPING>;
  saveCollectable: (context: Context, params: { params: SHIPPING_PARAMS; shippingDetails: SHIPPING; customQuery?: CustomQuery }) => Promise<SHIPPING>;
}

const useShippingMethods: UseShippingMethods<Address, any> = {
  provide() {
    return {
      cart: useCart()
    };
  },
  load: async (context: Context, { customQuery }) => {
    if (!context.cart.cart?.value?.shippingAddress) {
      await context.cart.load({ customQuery });
    }
    return context.cart.cart.value.shippingAddress;
  },
  save: async (context: Context, { shippingDetails, customQuery }) => {
    const response = await context.$ct.api.updateCart(
      {
        id: context.cart.cart.value.id,
        version: context.cart.cart.value.version,
        actions: [cartActions.setShippingMethodAction(), cartActions.setShippingAddressAction(shippingDetails)]
      },
      customQuery
    );
    catchApiErrors(response as ErrorResponse);
    context.cart.setCart(response.data.cart);

    return context.cart.cart.value.shippingAddress;
  },
  clear: async (context: Context, { customQuery }) => {
    const response = await context.$ct.api.updateCart(
      {
        id: context.cart.cart.value.id,
        version: context.cart.cart.value.version,
        actions: [cartActions.setShippingMethodAction()]
      },
      customQuery
    );
    catchApiErrors(response as ErrorResponse);
    context.cart.setCart(response.data.cart);

    return context.cart.cart.value.shippingAddress;
  },
  saveCollectable: async (context: Context, { shippingDetails, customQuery }) => {
    const { collectShippingKey } = context.$ct.config;
    const cartResponse = await context.$ct.api.updateCart({
      id: context.cart.cart.value.id,
      version: context.cart.cart.value.version,
      actions: [
        cartActions.setShippingMethodAction(),
        cartActions.setShippingAddressAction(shippingDetails),
        cartActions.setShippingMethodByKey(collectShippingKey)
      ]
    }, customQuery);
    catchApiErrors(cartResponse as ErrorResponse);
    context.cart.setCart(cartResponse.data.cart);
    return context.cart.cart.value.shippingAddress;
  }
};

export default useShippingMethods;

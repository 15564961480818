import type { Context, CustomQuery } from '@vue-storefront/core';
import type { Category } from '@vsf-enterprise/commercetools-types';

type UseCategoryMethods<CATEGORY, CATEGORY_SEARCH_PARAMS> = {
  categorySearch: (context: Context, params: CATEGORY_SEARCH_PARAMS & { customQuery?: CustomQuery }) => Promise<CATEGORY[]>;
}

const useCategoryMethods: UseCategoryMethods<Category, any> = {
  categorySearch: async (context: Context, { customQuery, ...searchParams }) => {
    const categoryResponse = await context.$ct.api.getCategory(searchParams, customQuery);
    return categoryResponse.data.categories.results;
  }
};

export default useCategoryMethods;

import type { ApolloQueryResult } from '@apollo/client/core';
import type { ProductQueryResult } from '@vsf-enterprise/commercetools-types';
import type { Context } from '@vue-storefront/core';

interface ProductData {
  products: ProductQueryResult;
}

const getTranslated = (rawAttribute, context) => {
  const { locale } = context.$ct.config;
  if (rawAttribute.attributeDefinition.type.name === 'ltext') {
    return rawAttribute.value[locale];
  }
  if (rawAttribute.attributeDefinition.type.name === 'lenum') {
    return rawAttribute.value.label[locale];
  }
  return rawAttribute.value;
};

export type EnhanceProduct = (productResponse: ApolloQueryResult<ProductData>, context: Context) => ApolloQueryResult<ProductData>;

export const enhanceProduct: EnhanceProduct = (productResponse, context) => {
  (productResponse.data as any)._variants = productResponse.data.products.results
    .map((product) => {
      const current = product.masterData.current;

      return current.allVariants.map((variant) => ({
        ...variant,
        attributesRaw: variant.attributesRaw.map((raw) => ({
          ...raw,
          _translated: getTranslated(raw, context)
        })),
        _name: current.name,
        _slug: current.slug,
        _id: product.id,
        _key: product.key,
        _master: current.masterVariant.id === variant.id,
        _description: current.description,
        _categoriesRef: current.categoriesRef.map((cr) => cr.id),
        _categories: current.categories.map(category => ({ id: category.id, name: category.name, slug: category.slug })),
        _rating: (product as any).reviewRatingStatistics,
        _original: product
      }));
    })
    .reduce((prev, curr) => [...prev, ...curr], []);

  return productResponse;
};

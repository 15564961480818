import generateContext from '../helpers/context';
import useUserShippingMethods from './useUserShippingMethods';

import { computed, unref } from '@nuxtjs/composition-api';
import { Logger, mask, sharedRef } from '@vue-storefront/core';
import { useUser } from '../useUser';

import type { Ref } from '@nuxtjs/composition-api';
import type { Context, UseUserShipping, UseUserShippingErrors } from '@vue-storefront/core';
import type { Address, Customer } from '@vsf-enterprise/commercetools-types';

/**
 * Composable for managing user shipping addresses
 */
function useUserShipping(): UseUserShipping<Partial<Customer>, Partial<Address>> {
  const { user, setUser } = useUser();

  const shipping: Ref<Partial<Customer>> = user;
  const context: Context = generateContext(useUserShippingMethods);
  const loading: Ref<boolean> = sharedRef(false, 'useUserShipping-loading');
  const readonlyShipping: Readonly<Partial<Customer>> = unref(shipping);
  const error: Ref<UseUserShippingErrors> = sharedRef({
    addAddress: null,
    deleteAddress: null,
    updateAddress: null,
    load: null,
    setDefaultAddress: null
  }, 'useUserShipping-error');

  /**
   * Adds user shipping address
   */
  async function addAddress({ address, customQuery }) {
    Logger.debug('useUserShipping.addAddress', mask(address));

    try {
      loading.value = true;
      setUser(await useUserShippingMethods.addAddress(context, {
        address,
        shipping: readonlyShipping,
        customQuery
      }));
      error.value.addAddress = null;
    } catch (err) {
      error.value.addAddress = err;
      Logger.error('useUserShipping/addAddress', err);
    } finally {
      loading.value = false;
    }
  }

  /**
   * Deletes user shipping address
   */
  async function deleteAddress({ address, customQuery }) {
    Logger.debug('useUserShipping.deleteAddress', address);

    try {
      loading.value = true;
      setUser(await useUserShippingMethods.deleteAddress(context, {
        address,
        shipping: readonlyShipping,
        customQuery
      }));
      error.value.deleteAddress = null;
    } catch (err) {
      error.value.deleteAddress = err;
      Logger.error('useUserShipping/deleteAddress', err);
    } finally {
      loading.value = false;
    }
  }

  /**
   * Updates user shipping address
   */
  async function updateAddress({ address, customQuery }) {
    Logger.debug('useUserShipping.updateAddress', address);

    try {
      loading.value = true;
      setUser(await useUserShippingMethods.updateAddress(context, {
        address,
        shipping: readonlyShipping,
        customQuery
      }));
      error.value.updateAddress = null;
    } catch (err) {
      error.value.updateAddress = err;
      Logger.error('useUserShipping/updateAddress', err);
    } finally {
      loading.value = false;
    }
  }

  /**
   * Loads saved user shipping address
   */
  async function load() {
    console.warn('useUserShipping.load method is deprecatd. It will be removed in one of the future releases.');
  }

  /**
   * Sets default user shipping address
   */
  async function setDefaultAddress({ address, customQuery }) {
    Logger.debug('useUserShipping.setDefaultAddress', address);

    try {
      loading.value = true;
      setUser(await useUserShippingMethods.setDefaultAddress(context, {
        address,
        shipping: readonlyShipping,
        customQuery
      }));
      error.value.setDefaultAddress = null;
    } catch (err) {
      error.value.setDefaultAddress = err;
      Logger.error('useUserShipping/setDefaultAddress', err);
    } finally {
      loading.value = false;
    }
  }

  return {
    shipping: computed(() => shipping.value),
    loading: computed(() => loading.value),
    error: computed(() => error.value),
    addAddress,
    deleteAddress,
    updateAddress,
    load,
    setDefaultAddress
  };
}

export {
  useUserShipping
};

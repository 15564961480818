import generateContext from '../helpers/context';
import useMakeOrderMethods from './useMakeOrderMethods';
import { computed } from '@nuxtjs/composition-api';
import { Logger, sharedRef } from '@vue-storefront/core';

import type { Ref } from '@nuxtjs/composition-api';
import type { Context, UseMakeOrder, UseMakeOrderErrors } from '@vue-storefront/core';
import type { Order } from '@vsf-enterprise/commercetools-types';

/**
 * Composable for placing an order
 */
function useMakeOrder(): UseMakeOrder<Order> {
  const context: Context = generateContext(useMakeOrderMethods);
  const order: Ref<Order> = sharedRef(null, 'useMakeOrder-order');
  const loading: Ref<boolean> = sharedRef(false, 'useMakeOrder-loading');
  const error: Ref<UseMakeOrderErrors> = sharedRef({
    make: null
  }, 'useMakeOrder-error');

  /**
   * Creates order from cart
   */
  async function make(params = { customQuery: null }) {
    Logger.debug('useMakeOrder.make', params);

    try {
      loading.value = true;
      const createdOrder = await useMakeOrderMethods.make(context, params);
      error.value.make = null;
      order.value = createdOrder;
    } catch (err) {
      error.value.make = err;
      Logger.error('useMakeOrder.make', err);
    } finally {
      loading.value = false;
    }
  }

  return {
    order,
    make,
    loading: computed(() => loading.value),
    error: computed(() => error.value)
  };
}

export {
  useMakeOrder
};

import { useUser } from '../useUser';

import type { Context, CustomQuery } from '@vue-storefront/core';
import type { Customer, Address } from '@vsf-enterprise/commercetools-types';

type UseUserBillingMethods<USER_BILLING, USER_BILLING_ITEM> = {
  provide: (context: Context) => any;
  addAddress: (
    context: Context,
    params: {
      address: Readonly<USER_BILLING_ITEM>;
      billing: Readonly<USER_BILLING>;
      customQuery?: CustomQuery;
    }) => Promise<USER_BILLING>;
  deleteAddress: (
    context: Context,
    params: {
      address: Readonly<USER_BILLING_ITEM>;
      billing: Readonly<USER_BILLING>;
      customQuery?: CustomQuery;
    }) => Promise<USER_BILLING>;
  updateAddress: (
    context: Context,
    params: {
      address: Readonly<USER_BILLING_ITEM>;
      billing: Readonly<USER_BILLING>;
      customQuery?: CustomQuery;
    }) => Promise<USER_BILLING>;
  setDefaultAddress: (
    context: Context,
    params: {
      address: Readonly<USER_BILLING_ITEM>;
      billing: Readonly<USER_BILLING>;
      customQuery?: CustomQuery;
    }) => Promise<USER_BILLING>;
}

async function handler(context, fn, params): Promise<Partial<Customer>> {
  return await fn({ ...params, user: context.user.value }, params.customQuery);
}

// TODO: Add support for custom queries when this is implemented: https://github.com/vuestorefront/vue-storefront/issues/5049
const useUserBillingMethods: UseUserBillingMethods<Partial<Customer>, Partial<Address>> = {
  provide () {
    const { user, load: loadUser, setUser } = useUser();
    return { user, loadUser, setUser };
  },
  addAddress: async (context, params) => handler(context, context.$ct.api.addBillingAddress, params),
  deleteAddress: async (context, params) => handler(context, context.$ct.api.deleteBillingAddress, params),
  updateAddress: async (context, params) => handler(context, context.$ct.api.updateBillingAddress, params),
  setDefaultAddress: async (context, params) => handler(context, context.$ct.api.setDefaultBillingAddress, params)
};

export default useUserBillingMethods;

import generateContext from '../helpers/context';
import useForgotPasswordMethods from './useForgotPasswordMethods';
import { computed, Ref } from '@nuxtjs/composition-api';
import { Context, Logger, sharedRef, UseForgotPassword, UseForgotPasswordErrors } from '@vue-storefront/core';

import type { ForgotPasswordResult, ResetPasswordParams, SetNewPasswordParams } from '../types';

/**
 * Composable for resetting user password
 */
function useForgotPassword(): UseForgotPassword<ForgotPasswordResult> {
  const context: Context = generateContext(useForgotPasswordMethods);
  const result: Ref<ForgotPasswordResult> = sharedRef({
    resetPasswordResult: null,
    setNewPasswordResult: null
  }, 'useForgotPassword-result');
  const loading = sharedRef(false, 'useProduct-loading');
  const error: Ref<UseForgotPasswordErrors> = sharedRef({
    request: null,
    setNew: null
  }, 'useForgotPassword-error');

  /**
   * Requests password reset
   */
  async function request(resetPasswordParams: ResetPasswordParams) {
    Logger.debug('useForgotPassword/request', resetPasswordParams.email);

    try {
      loading.value = true;
      result.value = await useForgotPasswordMethods.resetPassword(context, { currentResult: result.value, ...resetPasswordParams });
      error.value.request = null;
    } catch (err) {
      error.value.request = err;
      Logger.error('useForgotPassword/request', err);
    } finally {
      loading.value = false;
    }
  }

  /**
   * Sets new password
   */
  async function setNew(setNewPasswordParams: SetNewPasswordParams) {
    Logger.debug('useForgotPassword/setNew', setNewPasswordParams);

    try {
      loading.value = true;
      result.value = await useForgotPasswordMethods.setNewPassword(context, { currentResult: result.value, ...setNewPasswordParams });
      error.value.setNew = null;
    } catch (err) {
      error.value.setNew = err;
      Logger.error('useForgotPassword/setNew', err);
    } finally {
      loading.value = false;
    }
  }

  return {
    request,
    setNew,
    result: computed(() => result.value),
    loading: computed(() => loading.value),
    error: computed(() => error.value)
  };
}

export {
  useForgotPassword
};

import loadCurrentCart from './currentCart';
import { catchApiErrors } from '../helpers/internals/handleApiErrors';
import { useChannel } from '../useChannel';

import type { Context } from '@vue-storefront/core';
import type { Cart, Channel, LineItem, ProductVariant } from '@vsf-enterprise/commercetools-types';
import type { GetChannelParams, CustomQuery } from '@vsf-enterprise/commercetools-api';
import type { ErrorResponse, UseChannel } from '../types';

type UseCartMethods<CART, CART_ITEM, PRODUCT> = {
  provide: (context: Context) => { channel: UseChannel<Channel[], GetChannelParams> };
  load: (context: Context, params: { customQuery?: any }) => Promise<CART>;
  addItem: (
    context: Context,
    params: {
      currentCart: CART;
      product: PRODUCT;
      quantity: any;
      supplyChannel?: string;
      distributionChannel?: string;
      customQuery?: CustomQuery;
    }
  ) => Promise<CART>;
  removeItem: (context: Context, params: { currentCart: CART; product: CART_ITEM; customQuery?: CustomQuery }) => Promise<CART>;
  updateItemQty: (
    context: Context,
    params: {
      currentCart: CART;
      product: { id: string };
      quantity: number;
      customQuery?: CustomQuery
  }) => Promise<CART>;
  clear: (context: Context, params: { currentCart: CART }) => Promise<CART>;
  applyCoupon: (context: Context, params: { currentCart: CART; couponCode: string; customQuery?: CustomQuery }) => Promise<{ updatedCart: CART }>;
  removeCoupon: (context: Context, params: { currentCart: CART; couponCode: string; customQuery?: CustomQuery }) => Promise<{ updatedCart: CART }>;
  isInCart: (context: Context, params: { currentCart: CART; product: PRODUCT }) => boolean;
  setItemSupplyChannel: (context: Context, params: { currentCart: CART; product: CART_ITEM, customQuery?: CustomQuery }) => Promise<{ updatedCart: CART }>;
};

const getCartItemByProduct = ({ currentCart, product }) => {
  return currentCart.lineItems.find((item) => item.productId === product._id);
};

/** returns current cart or creates new one **/
async function getCurrentCartDetails(context: Context, currentCart: Partial<Cart>): Promise<Partial<Cart>> {
  return currentCart || await loadCurrentCart(context);
}

const useCartMethods: UseCartMethods<Partial<Cart>, LineItem, ProductVariant> = {
  provide: () => ({
    channel: useChannel()
  }),
  load: async (context: Context, { customQuery }) => {
    const response = await context.$ct.api.getMe({ customer: false }, customQuery);
    catchApiErrors(response as ErrorResponse);

    return response.data.me.activeCart;
  },
  addItem: async (context: Context, { currentCart, product, quantity, supplyChannel, distributionChannel, customQuery }) => {
    const cart = await getCurrentCartDetails(context, currentCart);

    const params: {
      product: ProductVariant;
      quantity: number;
      supplyChannel?: string;
      distributionChannel?: string;
    } = {
      product,
      quantity,
      supplyChannel,
      distributionChannel
    };

    const response = await context.$ct.api.addToCart(
      {
        id: cart.id,
        version: cart.version
      },
      params,
      customQuery
    );
    catchApiErrors(response as ErrorResponse);

    return response.data.cart;
  },
  removeItem: async (context: Context, { currentCart, product, customQuery }) => {
    const cart = await getCurrentCartDetails(context, currentCart);

    const response = await context.$ct.api.removeFromCart(
      {
        id: cart.id,
        version: cart.version
      },
      product,
      customQuery
    );
    catchApiErrors(response as ErrorResponse);

    return response.data.cart;
  },
  updateItemQty: async (context: Context, { currentCart, product, quantity, customQuery }) => {
    const cart = await getCurrentCartDetails(context, currentCart);

    const response = await context.$ct.api.updateCartQuantity(
      {
        id: cart.id,
        version: cart.version
      },
      { ...product, quantity },
      customQuery
    );
    catchApiErrors(response as ErrorResponse);

    return response.data.cart;
  },
  clear: async (context: Context, { currentCart }) => {
    const cart = await getCurrentCartDetails(context, currentCart);

    const response = await context.$ct.api.deleteCart({
      id: cart.id,
      version: cart.version
    });
    catchApiErrors(response as ErrorResponse);

    return response.data.cart;
  },
  applyCoupon: async (context: Context, { currentCart, couponCode, customQuery }) => {
    const cart = await getCurrentCartDetails(context, currentCart);

    const response = await context.$ct.api.applyCartCoupon(
      {
        id: cart.id,
        version: cart.version
      },
      couponCode,
      customQuery
    );
    catchApiErrors(response as ErrorResponse);

    return {
      updatedCart: response.data.cart,
      updatedCoupon: couponCode
    };
  },
  removeCoupon: async (context: Context, { currentCart, couponCode, customQuery }) => {
    const cart = await getCurrentCartDetails(context, currentCart);

    const couponId = cart.discountCodes.find((d) => d.discountCode.code === couponCode)?.discountCode?.id;

    if (!couponId) {
      return { updatedCart: currentCart };
    }

    const response = await context.$ct.api.removeCartCoupon(
      {
        id: cart.id,
        version: cart.version
      },
      {
        id: couponId,
        typeId: 'discount-code'
      },
      customQuery
    );
    catchApiErrors(response as ErrorResponse);

    return { updatedCart: response.data.cart };
  },
  isInCart: (context: Context, { currentCart, product }) => {
    return Boolean(currentCart && getCartItemByProduct({ currentCart, product }));
  },
  setItemSupplyChannel: async (context: Context, { currentCart, product, customQuery }) => {
    const { id, version } = await getCurrentCartDetails(context, currentCart);
    const response = await context.$ct.api.updateCartItemChannel(
      {
        id,
        version
      },
      {
        lineItem: product,
        channelId: context.channel.channel.value
      },
      customQuery
    );
    catchApiErrors(response as ErrorResponse);

    return {
      updatedCart: response.data.cart
    };
  }
};

export default useCartMethods;
